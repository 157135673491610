import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { ROUTER_UTILS } from '../utils';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if ([401, 403].includes(error.status)) {
            this.authService.signOut();
            this.router.navigateByUrl(
              `/${ROUTER_UTILS.config.auth.root}/${ROUTER_UTILS.config.auth.signIn}`
            );
          } else {
            throwError(()=>error);

          }
        },
      })
    );
  }
}
