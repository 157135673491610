import { Routes } from '@angular/router';
import { MANAGEMENT_ROUTER_UTILS } from 'src/app/core/utils/management-router.util';
import { ROUTER_UTILS } from 'src/app/core/utils/router.util';

export const content: Routes = [
  {
    path: ROUTER_UTILS.config.site.root,
    loadChildren: () =>
      import('../../pages/site/site.module').then((m) => m.SiteModule),
  },
  {
    path: ROUTER_UTILS.config.home.root,
    loadChildren: () =>
      import('../../pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: ROUTER_UTILS.config.dashboard.root,
    loadChildren: () =>
      import('../../pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: ROUTER_UTILS.config.products.root,
    loadChildren: () =>
      import('../../pages/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: ROUTER_UTILS.config.sales.root,
    loadChildren: () =>
      import('../../pages/sale/sale.module').then((m) => m.SaleModule),
  },
  {
    path: ROUTER_UTILS.config.returns.root,
    loadChildren: () =>
      import('../../pages/return/return.module').then((m) => m.ReturnModule),
  },
  {
    path: ROUTER_UTILS.config.autoPricing.root,
    loadChildren: () =>
      import('../../pages/auto-pricing/auto-pricing.module').then(
        (m) => m.AutoPricingModule
      ),
  },
  {
    path: ROUTER_UTILS.config.integrations.root,
    loadChildren: () =>
      import('../../pages/integration/accounting/accounting.module').then(
        (m) => m.AccountingModule
      ),
  },
  {
    path: ROUTER_UTILS.config.integrations.root,
    loadChildren: () =>
      import('../../pages/integration/market-place/market-place.module').then(
        (m) => m.MarketPlaceModule
      ),
  },
  {
    path: ROUTER_UTILS.config.accounting.root,
    loadChildren: () =>
      import('../../pages/accounting/accounting.module').then(
        (m) => m.AccountingModule
      ),
  },
  {
    path: ROUTER_UTILS.config.firm.root,
    loadChildren: () =>
      import('../../pages/company/company.module').then((m) => m.CompanyModule),
  },
  {
    path: ROUTER_UTILS.config.batchOperations.root,
    loadChildren: () =>
      import('../../pages/batch-operation/batch-operation.module').then(
        (m) => m.BatchOperationModule
      ),
  },
  {
    path: ROUTER_UTILS.config.values.root,
    loadChildren: () =>
      import('../../pages/value/values.module').then((m) => m.ValuesModule),
  },
  {
    path: ROUTER_UTILS.config.tracking.root,
    loadChildren: () =>
      import('../../pages/tracking/tracking.module').then(
        (m) => m.TrackingModule
      ),
  },
  {
    path: ROUTER_UTILS.config.settings.root,
    loadChildren: () =>
      import('../../pages/setting/setting.module').then((m) => m.SettingModule),
  },
  {
    path: ROUTER_UTILS.config.user.root,
    loadChildren: () =>
      import('../../pages/user/user.module').then((m) => m.UserModule),
  },
  {
    path: ROUTER_UTILS.config.retailSale.root,
    loadChildren: () =>
      import('../../pages/retail_sale/retail_sale.module').then(
        (m) => m.RetailSaleModule
      ),
  },
  {
    path: MANAGEMENT_ROUTER_UTILS.config.customers.root,
    loadChildren: () =>
      import('../../pages/management/customer/customer.module').then(
        (m) => m.CustomerModule
      ),
  },
  {
    path: MANAGEMENT_ROUTER_UTILS.config.users.root,
    loadChildren: () =>
      import('../../pages/management/user/user.module').then(
        (m) => m.UserModule
      ),
  },
  {
    path: MANAGEMENT_ROUTER_UTILS.config.roles.root,
    loadChildren: () =>
      import('../../pages/management/role/role.module').then(
        (m) => m.RoleModule
      ),
  },
  {
    path: MANAGEMENT_ROUTER_UTILS.config.version.root,
    loadChildren: () =>
      import('../../pages/management/version/version.module').then(
        (m) => m.VersionModule
      ),
  },
  {
    path: MANAGEMENT_ROUTER_UTILS.config.storeSettings.root,
    loadChildren: () =>
      import('../../pages/management/store-setting/store-setting.module').then(
        (m) => m.StoreSettingModule
      ),
  },
];
