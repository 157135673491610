import { Routes } from '@angular/router';
import { SignInComponent } from './pages/auth/sign-in/sign-in.component';
import { SignUpComponent } from './pages/auth/sign-up/sign-up.component';
import { TrialCreationComponent } from './pages/auth/trial-creation/trial-creation.component';
import { SiteBaseComponent } from './pages/site/site-base/site-base.component';

export const routes: Routes = [
  { path: '', component: SiteBaseComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'trial-creation', component: TrialCreationComponent },
];
