<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 p-0">
      <div class="login-card">
        <div>
          <!-- <div>
            <a class="logo text-left" href="#">
              <img
                width="300"
                height="200"
                class="img-fluid for-light"
                src="assets/images/logo/harpazar_logo.jpg"
                alt="looginpage"
              />
            </a>
          </div> -->
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Oturum Açın</h4>
              <p>Giriş yapmak için e-posta adresinizi ve şifrenizi girin</p>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input
                      matInput
                      formControlName="email"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Şifre</mat-label>
                    <input
                      matInput
                      formControlName="password"
                      [type]="show ? 'text' : 'password'"
                    />
                    <mat-icon
                      matSuffix
                      (click)="show = !show"
                      class="show-hide-icon"
                    >
                      {{ show ? "visibility_off" : "visibility" }}
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" />
                  <label class="text-muted" for="checkbox1">Beni Hatırla</label>
                </div> -->
                <a
                  routerLink="/{{ routerUtils.config.auth.root }}/{{
                    routerUtils.config.auth.forgotPassword
                  }}"
                  class="link"
                  >Şifrenimi unuttun?</a
                >
                <button
                  class="btn btn-primary btn-block"
                  type="submit"
                  [disabled]="!loginForm.valid"
                  (click)="onClickSignIn(loginForm.value)"
                >
                  Giriş Yap
                </button>
              </div>
              <p class="mt-4 mb-0">
                Bir Hesabın Yok Mu?
                <a
                  routerLink="/{{ routerUtils.config.auth.root }}/{{
                    routerUtils.config.auth.trialCreation
                  }}"
                  class="ml-2"
                  >Deneme Hesap Oluştur</a
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
