import { Component, type OnInit } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  standalone:true,
  imports:[SharedModule],
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrl: './user-agreement.component.scss',
})
export class UserAgreementComponent implements OnInit {

  ngOnInit(): void { }

}
