<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 p-0">
      <div class="login-card">
        <div>
          <!-- <div>
            <a class="logo text-left" href="#">
              <img
                width="300"
                height="200"
                class="img-fluid for-light"
                src="assets/images/logo/harpazar_logo.jpg"
                alt="looginpage"
              />
            </a>
          </div> -->
          <div class="login-main">
            <mat-stepper linear #stepper>
              <mat-step [stepControl]="creationFirstFormGroup">
                <ng-template matStepLabel>Kullanıcı Bilgileri</ng-template>
                <form [formGroup]="creationFirstFormGroup">
                  <h4>Deneme hesabı oluşturun</h4>
                  <p>Aşağıdaki formu eksiksiz bir şekilde doldurun</p>
                  <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Adınız</mat-label>
                        <input matInput formControlName="firstName" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Soyadınız</mat-label>
                        <input matInput formControlName="lastName" />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input
                          matInput
                          formControlName="email"
                          autocomplete="new-email"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Telefon</mat-label>
                        <input
                          matInput
                          formControlName="phoneNumber"
                          autocomplete="off"
                          mask="000-000-00-00"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Kullanıcı Adı</mat-label>
                        <input
                          matInput
                          formControlName="userName"
                          autocomplete="off"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Şifre</mat-label>
                        <input
                          matInput
                          formControlName="password"
                          autocomplete="new-password"
                          [type]="show ? 'text' : 'password'"
                        />
                        <mat-icon
                          matSuffix
                          (click)="show = !show"
                          class="show-hide-icon"
                        >
                          {{ show ? "visibility_off" : "visibility" }}
                        </mat-icon>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Şifre Tekrar</mat-label>
                        <input
                          matInput
                          formControlName="confirmPassword"
                          autocomplete="new-password"
                          [type]="show ? 'text' : 'password'"
                        />
                        <mat-error
                          *ngIf="
                            creationFirstFormGroup
                              .get('confirmPassword')
                              .hasError('notSame')
                          "
                        >
                          Şifreler uyuşmuyor
                        </mat-error>
                        <mat-icon
                          matSuffix
                          (click)="show = !show"
                          class="show-hide-icon"
                        >
                          {{ show ? "visibility_off" : "visibility" }}
                        </mat-icon>
                      </mat-form-field>
                    </div>
                  </div>
                  <p class="mb-1">
                   Deneme hesabını oluşturduğunuz takdirde
                    <a
                      style="cursor: pointer"
                      (click)="openUserAgreementDialog()"
                      class="ml-2"
                      >kullanıcı sözleşmesini</a
                    >
                    kabul etmiş sayılırsınız!!!
                  </p>
                  <div>
                    <button
                      class="btn btn-primary btn-block"
                      type="submit"
                      (click)="
                        onClickTrialCreationFirst(creationFirstFormGroup.value)
                      "
                    >
                      Deneme Hesabını Oluştur
                    </button>
                  </div>
                  <p class="mt-2 mb-0">
                    Bir Hesabın Var Mı?
                    <a
                      routerLink="/{{ routerUtils.config.auth.root }}/{{
                        routerUtils.config.auth.signIn
                      }}"
                      class="ml-2"
                      >Giriş Yap</a
                    >
                  </p>
                </form>
              </mat-step>
              <mat-step [stepControl]="creationSecondFormGroup">
                <form [formGroup]="creationSecondFormGroup">
                  <ng-template matStepLabel>Onay Kodu</ng-template>
                  <p>Telefonuza gelen onay kodunu giriniz.</p>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Onay Kodu</mat-label>
                        <input
                          matInput
                          formControlName="code"
                          autocomplete="off"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div>
                    <button
                      class="btn btn-primary btn-block"
                      type="submit"
                      (click)="
                        onClickTrialCreationSecond(
                          creationSecondFormGroup.value
                        )
                      "
                    >
                      Gönder
                    </button>
                  </div>
                </form>
              </mat-step>
            </mat-stepper>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
