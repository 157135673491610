import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth_guard';
import { ROUTER_UTILS } from './core/utils';
import { SignInComponent } from './pages/auth/sign-in/sign-in.component';
import { ContentComponent } from './shared/layout/content/content.component';
import { FullComponent } from './shared/layout/full/full.component';
import { full } from './shared/routes/full.routes';
import { content } from './shared/routes/routes';
import { SiteBaseComponent } from './pages/site/site-base/site-base.component';
import { TrialCreationComponent } from './pages/auth/trial-creation/trial-creation.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { UserAgreementComponent } from './pages/contracts/user-agreement/user-agreement.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    component: SiteBaseComponent,
  },
  {
    path: `${ROUTER_UTILS.config.auth.root}/${ROUTER_UTILS.config.auth.signIn}`,
    component: SignInComponent,
  },
  {
    path: `${ROUTER_UTILS.config.auth.root}/${ROUTER_UTILS.config.auth.trialCreation}`,
    component: TrialCreationComponent,
  },
  {
    path: `${ROUTER_UTILS.config.contracts.root}/${ROUTER_UTILS.config.contracts.userAgreement}`,
    component: UserAgreementComponent,
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content,
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: full,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
