import { Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from './core/services/auth';
import { AlertDialogService } from './core/services/alert-dialog';
import { SpinnerService } from './core/services/spinner';
import { environment } from '../environments/environment';
import { getToken, Messaging, onMessage } from '@angular/fire/messaging';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent {
  private messaging = inject(Messaging);
  constructor(
    private authService: AuthService,
    private alertDialogService: AlertDialogService,
    public spinnerService: SpinnerService,
    private router: Router
  ) {
    const isLoggedIn = this.authService.isLoggedIn;
    if (isLoggedIn) {
      this.authService.loadUserRole();
    }
  }
  ngOnInit(): void {
    this.requestPermission();
    this.listen();
  }

  requestPermission() {
    getToken(this.messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log('FCM Token alındı');
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  listen() {
    onMessage(this.messaging, (payload) => {
      console.log('Message received. ', payload);
      this.alertDialogService.info(payload.notification!.body!);
    });
  }

  showSpinner(): void {
    this.spinnerService.showSpinner('Loading...');
  }

  hideSpinner(): void {
    this.spinnerService.hideSpinner();
  }
}
