import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Roles } from 'src/app/core/constants';
import { SignInDto } from 'src/app/core/dtos/auth';
import { AuthService } from 'src/app/core/services/auth';
import { ROUTER_UTILS } from 'src/app/core/utils';
import { MANAGEMENT_ROUTER_UTILS } from 'src/app/core/utils/management-router.util';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
})
export class SignInComponent implements OnInit {
  routerUtils = ROUTER_UTILS;
  loginForm!: FormGroup;
  returnUrl: string;
  show = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    if (this.authService.isLoggedIn) {
      if (this.authService.userRoleValue == Roles.superAdmin) {
        this.router.navigateByUrl(
          `/${MANAGEMENT_ROUTER_UTILS.config.version.root}/${MANAGEMENT_ROUTER_UTILS.config.version.versions}`
        );
      } else {
        this.router.navigateByUrl(
          `/${ROUTER_UTILS.config.home.root}/${ROUTER_UTILS.config.home.versions}`
        );
      }
    }

    this.returnUrl =
      this.activatedRoute.snapshot.queryParamMap.get('returnUrl') ||
      `/${ROUTER_UTILS.config.home.root}/${ROUTER_UTILS.config.home.versions}`;

    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  ngOnInit(): void {}

  onClickSignIn(data: SignInDto): void {
    this.authService.signIn(data, this.returnUrl);
  }
  showPassword(): void {
    this.show = !this.show;
  }
}
