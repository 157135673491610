import { CommonModule } from '@angular/common';
import { Component, TemplateRef, ViewChild, type OnInit } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  TrialCreationFirstDto,
  TrialCreationFirstResultDto,
  TrialCreationSecondDto,
} from 'src/app/core/dtos/auth';
import { AuthService } from 'src/app/core/services/auth';
import { httpErrorResponseUtil, ROUTER_UTILS } from 'src/app/core/utils';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { markFormGroupTouched } from 'src/app/core/utils/forms/mark_form_group_touched.util';
import { ResultDto } from 'src/app/core/dtos/api';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'src/app/core/services/message';
import { SharedModule } from 'src/app/shared/shared.module';
import { GeneralUserAgreementDialogComponent } from 'src/app/shared/components/general-user-agreement-dialog/general-user-agreement-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-trial-creation',
  templateUrl: './trial-creation.component.html',
  styleUrls: ['./trial-creation.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatStepperModule,
    SharedModule,
  ],
})
export class TrialCreationComponent implements OnInit {
  routerUtils = ROUTER_UTILS;
  creationFirstFormGroup!: UntypedFormGroup;
  creationSecondFormGroup!: UntypedFormGroup;

  returnUrl: string;
  show = false;

  @ViewChild('stepper') private myStepper: MatStepper;

  trialCreationFirstResult: TrialCreationFirstResultDto;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.returnUrl =
      this.activatedRoute.snapshot.queryParamMap.get('returnUrl') ||
      `/${ROUTER_UTILS.config.home.root}/${ROUTER_UTILS.config.home.versions}`;

    this.creationFirstFormGroup = this.formBuilder.group(
      {
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        phoneNumber: [null, Validators.required],
        password: [null, Validators.required],
        confirmPassword: [null, Validators.required],
        userName: [null, Validators.required],
      },
      { validators: this.checkPasswords }
    );

    this.creationSecondFormGroup = this.formBuilder.group({
      code: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {}

  onClickTrialCreationFirst(data: TrialCreationFirstDto): void {
    markFormGroupTouched(this.creationFirstFormGroup);
    if (this.creationFirstFormGroup.invalid) {
      return;
    }

    this.authService.trialCreationFirst(data).subscribe(
      (data: ResultDto<TrialCreationFirstResultDto>) => {
        if (data.success) {
          this.trialCreationFirstResult = data.data;

          this.goToStep(1);
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorText = httpErrorResponseUtil(error);
        this.messageService.error(errorText);
      }
    );
  }

  onClickTrialCreationSecond(data: TrialCreationSecondDto) {
    markFormGroupTouched(this.creationFirstFormGroup);
    if (this.creationFirstFormGroup.invalid) {
      return;
    }

    data.uniqueId = this.trialCreationFirstResult.uniqueId;

    this.authService.trialCreationSecond(data).subscribe(
      (data: ResultDto) => {
        if (data.success) {
          this.messageService.success(data.message);

          this.router.navigateByUrl(
            `/${ROUTER_UTILS.config.auth.root}/${ROUTER_UTILS.config.auth.signIn}`
          );
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorText = httpErrorResponseUtil(error);
        this.messageService.error(errorText);
      }
    );
  }

  showPassword(): void {
    this.show = !this.show;
  }

  checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;

    if (pass === confirmPass) {
      group.get('confirmPassword').setErrors(null);
      return null;
    } else {
      group.get('confirmPassword').setErrors({ notSame: true });
      return { notSame: true };
    }
  };

  goToStep(index: number) {
    this.myStepper.selectedIndex = index;
  }

  openUserAgreementDialog(): void {
    this.dialog.open(GeneralUserAgreementDialogComponent, {
      maxHeight: '80vh',
      width: '800px',
    });
  }
}
